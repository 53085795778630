import { mapGetters } from 'vuex';
import { customMapActions } from '@/vue/stores/Store';

import { ACTIONS, GETTERS, NAMESPACE } from '@/vue/stores/Invoices/InvoicesModule';

export const {
  invoicesSelected,
  invoicesDownloading,
  rawInvoicesPdf,

  fetchInvoices,
  toggleInvoiceSelection,
  unselectAllInvoices,
  downloadInvoices,
} = {
  ...mapGetters(NAMESPACE, {
    invoicesSelected: GETTERS.invoicesSelected,
    invoicesDownloading: GETTERS.invoicesDownloading,

    rawInvoicesPdf: GETTERS.rawInvoicesPdf,
  }),

  ...customMapActions(NAMESPACE, {
    fetchInvoices: ACTIONS.fetchInvoices,

    toggleInvoiceSelection: ACTIONS.toggleInvoiceSelection,
    unselectAllInvoices: ACTIONS.unselectAllInvoices,
    downloadInvoices: ACTIONS.downloadInvoices,
  }),
};
