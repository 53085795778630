<script>
import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import size from 'lodash/size';
import { aclService } from '@emobg/access-utils';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { GTMModalEvents } from '@/constants/gtm';
import { mapActions, mapState } from 'vuex';
import {
  CURRENCY_SYMBOLS,
  DISTANCE_UNIT,
  FALLBACK_MESSAGE,
  formatCurrency,
} from '@emobg/web-utils';
import { COLORS, GRAYSCALE } from '@emobg/web-components';
import { PAYMENT_CHANNELS, PAYMENT_REDIRECTED, RESPONSE_STATUS } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import PaymentSuccessModal from '@/components/PaymentMethods/PaymentSuccessModal';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useTranslations } from '@/composable/App/Translations';
import collectBrowserInfo from '@Bookings/Summary/adyen/collectBrowserInfo';
import { nameSpace as TARIFFS_NAMESPACE, scopes as TARIFFS_SCOPES } from '@/stores/Tariffs/TariffsModule';
import { userCompanyUuid, userUuid } from '@/stores/User/UserData/UserDataMapper';
import { SELECT_COMPANY_TARIFF } from '@/constants/permissions';
import { successOk } from '@/utils/publicImages';
import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { storage } from '@Shared/Payments/PaymentMethodsHub/composables/storage';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { useTheme } from '@/composable/Theme/useTheme';

import CancelTariffSubscriptionModal from './CancelTariffSubscriptionModal';
import TariffPSD2Modal from '../../TariffPSD2Modal/TariffPSD2Modal';

export default {
  name: 'TariffCardComponent',

  components: {
    TariffPSD2Modal,
    PaymentSuccessModal,
    CancelTariffSubscriptionModal,

    FeedbackModalComponent,
  },

  inject: ['paymentSuccess'],

  props: {
    tariff: {
      type: Object,
      required: true,
    },
    hasPaidTariff: {
      type: Boolean,
      default: false,
    },
    isBusinessProfile: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { notifySuccess, notifyError } = useNotifications();
    const { $t } = useTranslations();
    const { fetchButtonSpecs } = useTheme();
    return {
      $t,
      notifyError,
      notifySuccess,
      trackPageView,
      trackModalView,
      fetchButtonSpecs,
    };
  },

  data() {
    return {
      isContractTariffModalOpen: false,
      isSuccessModalVisible: false,
      isCancelTariffOpen: false,

      modals: {
        active: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    ...mapState(CSOperatorNameSpace, {
      currencyCode: state => get(state, 'currentOperatorConfig.configuration.currency_code', CURRENCY_SYMBOLS.EUR.code),
      mileageUnit: state => get(state, 'currentOperatorConfig.configuration.mileage_symbol', DISTANCE_UNIT.kilometers),
    }),
    ...mapState(TARIFFS_NAMESPACE, {
      postTariffStatus: state => state[TARIFFS_SCOPES.postTariff].STATUS,
      postTariffError: state => state[TARIFFS_SCOPES.postTariff].error,
    }),
    userUuid,
    userCompanyUuid,
    isCurrent() {
      return get(this, 'tariff.active');
    },
    isRecommended() {
      return get(this, 'tariff.recommended');
    },
    isSelectableTariff() {
      return !this.isCurrent && !this.hasPaidTariff;
    },
    isExpired() {
      return get(this, 'tariff.expiryDate') && moment(get(this, 'tariff.expiryDate')).isBefore(moment());
    },
    badgeText() {
      return this.isRecommended && !this.isCurrent ? this.$t('personal_profile.tariff_card_component.badge_recommended') : this.$t('personal_profile.tariff_card_component.badge_current');
    },
    badgeColor() {
      return this.isRecommended && !this.isCurrent ? COLORS.success : GRAYSCALE.ground;
    },
    categories() {
      return get(this, 'tariff.categories', []);
    },
    featureList() {
      const freeMileage = { text: this.freeMileageText, dataTestId: 'free-mileage' };
      const insuranceIncluded = { text: this.$t('personal_profile.tariff.basic_insurance_included'), dataTestId: 'insurance-included' };
      const fuelIncluded = { text: this.$t('personal_profile.tariff.fuel_included'), dataTestId: 'fuel-included' };
      return [
        insuranceIncluded,
        fuelIncluded,
        ...this.freeMileageText ? [freeMileage] : [],
      ];
    },
    monthlyPrice() {
      const defaultPrice = formatCurrency(0, this.currencyCode);
      const defaultPriceFormatted = this.currencyCode !== CURRENCY_SYMBOLS.EUR.code ? defaultPrice : defaultPrice.replace('.', ',');
      return get(this, 'tariff.subscriptions[0].priceMonth', defaultPriceFormatted);
    },
    nextPayday() {
      return moment(get(this, 'tariff.nextPayday')).format('DD MMM YY');
    },
    hasSubscriptions() {
      return size(get(this, 'tariff.subscriptions', []));
    },
    formattedTariff() {
      return {
        ...this.tariff,
        quotas: get(this, 'tariff.subscriptions', []),
      };
    },
    isMigratedTariff() {
      return get(this, 'tariff.isMigrated', false);
    },
    hasNextPayday() {
      const nextPayday = get(this, 'tariff.nextPayday');
      const isNextPaydayInFuture = nextPayday && moment(nextPayday).isAfter(moment());

      return isNextPaydayInFuture && this.hasSubscriptions && this.isCurrent;
    },
    freeMileageText() {
      const isMigratedTariff = get(this, 'tariff.isMigrated');
      const legacyFreeMileageText = get(this, 'tariff.freeMileage', null);
      const categoryWithFreeMileage = find(get(this, 'tariff.categories', []), category => {
        const firstMileage = get(category, 'prices.mileage[0]', null);
        return firstMileage && firstMileage.end && firstMileage.rawValue === 0;
      });

      const newFreeMileageValue = categoryWithFreeMileage ? get(categoryWithFreeMileage, 'prices.mileage[0].end', 0) - get(categoryWithFreeMileage, 'prices.mileage[0].start', 0) : null;
      const freeMileageText = newFreeMileageValue ? this.$t('tariff.price_component.free_migrated_mileage_text', { mileage: `${newFreeMileageValue} ${this.mileageUnit}` }) : null;
      return isMigratedTariff ? freeMileageText : legacyFreeMileageText;
    },
    postMigratedTariff() {
      return this.isBusinessProfile ? this.postMigratedTariffToCompany : this.postMigratedTariffToUser;
    },
    postLegacyTariff() {
      return this.isBusinessProfile ? this.postTariffToCompany : this.postTariffToUser;
    },
    postTariffRequest() {
      return this.isMigratedTariff ? this.postMigratedTariff : this.postLegacyTariff;
    },
    dataForRequest() {
      const entity = this.isBusinessProfile ? { companyUuid: this.userCompanyUuid } : { userUuid: this.userUuid };

      return {
        tariffUuid: get(this, 'tariff.uuid'),
        data: {
          origin: window.location.origin,
          channel: PAYMENT_CHANNELS.web,
          returnUrl: window.location.href,
          periodicity: null,
          browserInfo: collectBrowserInfo(),
          ...this.isMigratedTariff ? { ...entity } : {},
        },
        ...entity,
      };
    },
  },

  watch: {
    async paymentSuccess(value) {
      if (value.response.matchingName === this.tariff.name) {
        if (value.isValid) {
          this.closePSD2Modal();
          this.showSuccessFeedback();
        }

        // only show refused feedback upon redirect or challenge/identify flows, as the rest will be shown on the payment method modal itself
        const additionalStepResponses = [RESPONSE_STATUS.challenge, RESPONSE_STATUS.identify];

        if (
          !value.isValid && (storage.get(PAYMENT_REDIRECTED)
          || additionalStepResponses.includes(value.response.loopOrigin)
          )) {
          this.showRefusedFeedback(value.response);

          storage.remove(PAYMENT_REDIRECTED);
        }
      }
    },
  },

  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;

    this.canSelectTariff = this.isBusinessProfile ? aclService.hasPermissions(SELECT_COMPANY_TARIFF) : true;
    this.successOk = successOk;

    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.tariff.success.title'),
      primaryCallToAction: () => {
        this.closeContractModal(true);

        this.modals.active.isOpen = false;
      },
    };
  },

  methods: {
    get,
    ...mapActions(TARIFFS_NAMESPACE, [
      'postTariffToUser',
      'postMigratedTariffToUser',
      'postTariffToCompany',
      'postMigratedTariffToCompany',
    ]),
    selectTariff() {
      if (this.hasSubscriptions) {
        this.openContractModal();
      } else {
        this.contractTariff();
      }
    },
    async contractTariff() {
      await this.postTariffRequest(this.dataForRequest);

      if (!this.postTariffStatus.ERROR) {
        this.notifySuccess({
          text: this.$t('modal.tariff.confirm.contract_modal'),
          textAction: this.$t('buttons.ok'),
        });
        this.$emit('updated:tariff');
      } else {
        const keyError = this.postTariffError.key;
        const defaultError = this.postTariffError.message || this.$t('notifications.whooops');

        this.notifyError({
          text: this.$t(`api.${keyError}`, defaultError),
          textAction: this.$t('buttons.ok'),
        });
      }
    },

    openContractModal() {
      this.trackModalView({ modalName: GTMModalEvents.selectPaidTariffConfirmationModal });
      this.isContractTariffModalOpen = true;
    },

    closeContractModal(isSuccessContracted) {
      this.isContractTariffModalOpen = false;

      if (isSuccessContracted) {
        this.isSuccessModalVisible = true;
        this.trackPageView();
        this.$emit('updated:tariff');
      }
    },

    async closePSD2Modal() {
      this.isContractTariffModalOpen = false;
    },

    showSuccessFeedback() {
      this.modals.active = {
        ...this.successModalArgs,
        isOpen: true,
      };
    },

    showRefusedFeedback(response) {
      this.modals.active = {
        ...genericErrorArgs(this.$t),
        title: response.providerMessage || this.$t('modal.confirm_payment.error.title'),
        description: '',
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.active.isOpen = false;
        },
      };
    },
  },
};
</script>
<template>
  <div
    :class="[
      'TariffCardComponent d-flex flex-column py-5 px-4 position-relative',
      'emobg-background-color-white emobg-border-radius-medium',
      { 'emobg-border-1 emobg-border-color-success ': isRecommended && !isCurrent },
      { 'TariffCardComponent--withSubscription': hasSubscriptions },
    ]"
    data-test-id="tariff-card"
  >
    <div
      v-if="isRecommended || isCurrent"
      class="d-flex justify-content-center"
    >
      <ui-badge
        :text="badgeText"
        :color="badgeColor"
        class="TariffCardComponent__badge"
      />
    </div>

    <div class="flex-fill d-flex flex-column">
      <div class="TariffCardComponent__subtitle emobg-subtitle-m text-center mb-1">
        {{ tariff.name }}
      </div>
      <div class="TariffCardComponent__price emobg-title-m text-center">
        {{ monthlyPrice }} <span class="emobg-font-small emobg-font-weight-normal">{{ $t('personal_profile.tariff_card_component.monthly') }}</span>
      </div>

      <div
        v-if="categories.length"
        class="TariffCardComponent__table mt-4"
      >
        <table class="w-100">
          <thead class="emobg-body-m-regular emobg-color-ink-light emobg-border-color-ground-light emobg-border-bottom-1">
            <tr>
              <th
                :id="$t('personal_profile.tariff.category')"
                class="TariffCardComponent__table__header pb-2"
              >
                {{ $t('personal_profile.tariff.category') }}
              </th>
              <th
                :id="$t('personal_profile.tariff.hour_price')"
                class="TariffCardComponent__table__header pb-2 pr-3"
              >
                {{ $t('personal_profile.tariff.hour_price') }}*
              </th>
              <th
                :id="$t('personal_profile.tariff.day_price')"
                class="TariffCardComponent__table__header pb-2"
              >
                {{ $t('personal_profile.tariff.day_price') }}*
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="category in categories"
              :key="category.uuid"
              class="emobg-body-m-regular emobg-color-ink"
            >
              <td class="py-2 emobg-border-color-ground-light emobg-border-bottom-1">
                {{ category.name }}
              </td>
              <td class="py-2 emobg-border-color-ground-light emobg-border-bottom-1">
                {{ get(category, 'prices.hourly[0].value', FALLBACK_MESSAGE.dash) }}
              </td>
              <td class="py-2 emobg-border-color-ground-light emobg-border-bottom-1">
                {{ get(category, 'prices.daily[0].value', FALLBACK_MESSAGE.dash) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="emobg-font-small emobg-color-ink-light py-2">
        * {{ $t('personal_profile.tariff_card_component.note_1') }}
      </div>

      <div
        v-for="(feature, index) in featureList"
        :key="index"
        class="d-flex align-items-center emobg-color-success mb-1"
      >
        <ui-icon
          :icon="ICONS.check"
          :size="ICONS_SIZES.small"
          class="mr-1"
        />
        <span
          class="emobg-color-ink-light"
          :data-test-id="feature.dataTestId"
          v-text="feature.text"
        />
      </div>
    </div>

    <div class="d-flex align-items-center flex-column mt-3">
      <ui-button
        v-if="isSelectableTariff && canSelectTariff"
        v-bind="fetchButtonSpecs()"
        :size="SIZES.large"
        :disabled="postTariffStatus.LOADING"
        data-test-id="select_tariff-button"
        @clickbutton="selectTariff"
      >
        {{ $t('personal_profile.action.select') }}
      </ui-button>

      <template v-if="hasNextPayday && canSelectTariff">
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.DESTRUCTIVE })"
          :size="SIZES.large"
          :disabled="postTariffStatus.LOADING"
          data-test-id="cancel-reneval-button"
          @clickbutton="isCancelTariffOpen = true"
        >
          {{ $t('personal_profile.tariff_card_component.cancel') }}
        </ui-button>
        <span class="emobg-color-ink-light">{{ $t('personal_profile.tariff_card_component.renewal', { date: nextPayday }) }}</span>
      </template>

      <ui-button
        v-else-if="get(tariff, 'expiryDate') && !isExpired && hasSubscriptions"
        v-bind="fetchButtonSpecs()"
        :size="SIZES.large"
        disabled
        data-test-id="tariff-expiration-date"
      >
        {{ $t('personal_profile.tariff_card_component.expiry', { date: moment(get(tariff, 'expiryDate')).format('DD MMM YY') }) }}
      </ui-button>
    </div>

    <TariffPSD2Modal
      v-if="isContractTariffModalOpen"
      :tariff="formattedTariff"
      data-test-id="psd2-modal"
      @close-modal="closeContractModal"
    />
    <PaymentSuccessModal
      v-if="isSuccessModalVisible"
      data-test-id="psd2-success-modal"
      :title="$t('modal.tariff.success.title')"
      :background="successOk"
      @on:close="() => isSuccessModalVisible = false"
    />
    <CancelTariffSubscriptionModal
      v-if="isCancelTariffOpen"
      :is-visible="isCancelTariffOpen"
      :is-business-profile="isBusinessProfile"
      :tariff="tariff"
      :on-success="() => $emit('updated:tariff')"
      @close-modal="isCancelTariffOpen = false"
    />

    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>

<style lang="scss">
.TariffCardComponent {
  width: 356px;
  padding-top: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.32);

  &__badge {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  &__table {
    &__header {
      &:first-child {
        width: 50%;
      }
    }
  }
}
</style>
