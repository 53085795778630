<script>
import {
  camelCaseKeys, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';

import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toLower from 'lodash/toLower';

import ALERT_TYPES from '@/components/Alert/alertTypes';

import AlertComponent from '@/components/Alert/AlertComponent';

import isNull from 'lodash/isNull';

import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import {
  ACTIONS, HUB_REDIRECT_FLOW_STATUS, RESPONSE_STATUS, SOURCE_PAGES,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';

import {
  postCompanyMigratedTariffsPayment as hubPostCompanyMigratedTariffsPayment, postCompanyTariffsPayment as hubPostCompanyTariffsPayment,
  postUserMigratedTariffsPayment as hubPostUserMigratedTariffsPayment,
  postUserTariffsPayment as hubPostUserTariffsPayment,
} from '@Shared/Payments/PaymentMethodsHub/api/paymentMethodsApi';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import { removeUrlQuery } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'TariffPSD2Modal',

  components: {
    AlertComponent,
    PaymentMethodsHub,
  },

  inject: ['paymentSuccess'],

  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },

    hideCvc: {
      type: Boolean,
      default: false,
    },

    selectLocked: {
      type: Boolean,
      default: false,
    },
  },

  setup(_props, context) {
    const { checkPaymentIsFinalStatus, setNewResponse } = commonImplementationHelper(context);
    const { fetchButtonSpecs } = useTheme();

    return {
      checkPaymentIsFinalStatus,
      setNewResponse,
      fetchButtonSpecs,
    };
  },

  data() {
    return {
      quota: null,

      errorMessage: null,
      isPaymentMethodValid: false,
      currentPaymentMethod: null,
      PaymentResponse: null,
      isPaymentLoading: false,
    };
  },

  computed: {
    isTariffPaymentValid() {
      return !isEmpty(this.quota) && this.isPaymentMethodValid;
    },

    isTariffMigrated() {
      return get(this.tariff, 'isMigrated');
    },

    normalizedQuotas() {
      return map(this.tariff.quotas, ({ period, price }) => {
        const itemPeriod = toLower(this.$t(`modal.tariff.quotas.${period}`));

        return {
          label: `${this.$t(`modal.tariff.quotas.${period}ly`)} - ${this.$t('modal.tariff.confirm.price_period', {
            item_price: price,
            item_period: itemPeriod,
          })}`,
          value: { period, price },
        };
      });
    },
  },

  watch: {
    normalizedQuotas: {
      immediate: true,
      handler(newNormalizedQuotas) {
        this.quota = get(head(newNormalizedQuotas), 'value', {});
      },
    },

    paymentSuccess({ response }) {
      this.setIsPaymentLoading(!this.checkPaymentIsFinalStatus(response));
    },
  },

  created() {
    this.ALERT_TYPES = ALERT_TYPES;

    this.ACTIONS = ACTIONS;

    this.hubPostCompanyTariffsPayment = hubPostCompanyTariffsPayment;
    this.hubPostUserTariffsPayment = hubPostUserTariffsPayment;
    this.hubPostCompanyMigratedTariffsPayment = hubPostCompanyMigratedTariffsPayment;
    this.hubPostUserMigratedTariffsPayment = hubPostUserMigratedTariffsPayment;
  },

  methods: {
    closePSD2Modal() {
      this.errorMessage = null;
      this.$emit('close-modal', false);
    },

    onPaymentMethodValid(method) {
      this.setIsPaymentValid(method.isValid);
      this.setCurrentPaymentMethod(method);
      this.setErrorMsg(null);
    },

    setErrorMsg(message) {
      this.errorMessage = message;
    },

    setIsPaymentValid(isValid) {
      this.isPaymentMethodValid = isValid;
    },

    setIsPaymentLoading(isLoading) {
      this.isPaymentLoading = isLoading;
    },

    setCurrentPaymentMethod(method) {
      this.currentPaymentMethod = method;
    },

    async submitPayment() {
      this.setErrorMsg(null);

      const { isValid } = this.currentPaymentMethod;

      if (!isValid) {
        return;
      }

      const {
        userUuid, companyUuid, isBusinessProfile, isBusinessInCompanyPays,
      } = this.currentPaymentMethod.profile;

      if (isBusinessProfile && !isBusinessInCompanyPays) {
        // TODO: https://europcarmobility.atlassian.net/browse/CSREV-1696
        logger.message('*** flow in employee pays is not working ***', LOG_TYPE.error);
      }

      const uuid = isBusinessProfile
        ? { companyUuid }
        : { userUuid };

      const { securityCode } = this.currentPaymentMethod.paymentMethod;

      const request = {
        ...this.currentPaymentMethod,
        ...uuid,
        securityCode,

        periodicity: get(this, 'quota.period'),
      };

      const tariffUuid = this.tariff.uuid;

      this.setIsPaymentLoading(true);

      let apiCall = isBusinessProfile
        ? 'hubPostCompanyTariffsPayment'
        : 'hubPostUserTariffsPayment';

      let providedUuid = null;

      if (this.isTariffMigrated) {
        apiCall = isBusinessProfile
          ? 'hubPostCompanyMigratedTariffsPayment'
          : 'hubPostUserMigratedTariffsPayment';

        providedUuid = isBusinessProfile ? uuid.companyUuid : uuid.userUuid;
      }

      let originalResponse = {};

      try {
        originalResponse = this.isTariffMigrated
          ? await this[apiCall](providedUuid, tariffUuid, request)
          : await this[apiCall](tariffUuid, request);
      } catch (error) {
        logger.message(error.response.data.message, LOG_TYPE.error);

        originalResponse.providerStatus = RESPONSE_STATUS.error;
        originalResponse.providerMessage = error.response.data.exception_message || error.response.data.message;
      }

      removeUrlQuery(window.location.href, window.location.search);

      const response = camelCaseKeys(originalResponse.data) || camelCaseKeys(originalResponse);

      if (response.providerStatus === RESPONSE_STATUS.refused || response.providerStatus === RESPONSE_STATUS.error) {
        this.setErrorMsg(response.providerMessage);
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      const matchingResponse = {
        ...response,
        matchingName: this.tariff.name,
      };

      const hierarchy = {
        ancestor: SOURCE_PAGES.tariffs,
        parent: this.tariff.name,
      };

      const finalResponse = this.setNewResponse(matchingResponse, response.providerStatus, hierarchy);

      if (isNull(finalResponse)) {
        this.setIsPaymentLoading(false);
      } else {
        this.PaymentResponse = finalResponse;
      }
    },
  },
};
</script>

<template>
  <ui-modal
    :header="$t('modal.tariff.confirm.title', { tariff_name: tariff.name })"
    data-test-id="tariff-psd2-modal"
    open
  >
    <div
      slot="body"
      data-test-id="psd2-tariff-modal"
      class="text-left"
    >
      <template v-if="tariff.quotas.length">
        <div class="mb-n4 emobg-font-small">
          {{ $t('modal.tariff.confirm.select_label') }}
        </div>

        <AlertComponent
          v-if="errorMessage"
          :type="ALERT_TYPES.danger"
          class="w-100 mb-1 d-block"
          data-test-id="modal-error"
        >
          {{ errorMessage }}
        </AlertComponent>

        <ui-select
          :value="normalizedQuotas[0].label"
          :options.prop="normalizedQuotas"
          name="quotas"
          class="d-block mb-3"
          @selectoption="({ detail }) => quota = detail"
        />
      </template>

      <PaymentMethodsHub
        :action="ACTIONS.select"
        :hide-cvc="hideCvc"
        :select-locked="selectLocked"
        :handle-external-response="PaymentResponse"
        @update:helper-component-active="setIsPaymentValid"
        @update:payment-method-valid="onPaymentMethodValid"
        @on:payment-error="setErrorMsg"
      />
      <div
        v-if="quota"
        class="mt-3"
      >
        <div class="row">
          <div class="col-12 emobg-font-small">
            {{ $t('modal.tariff.confirm.subscription', { name: tariff.name }) }}
          </div>
        </div>
        <div class="emobg-border-horizontal-2 emobg-border-color-ground-light py-3 mb-2">
          <div class="row">
            <div class="col-10">
              {{ $t('modal.tariff.confirm.fee', { fee: $t(`modal.tariff.quotas.${quota.period}ly`) }) }}
            </div>
            <div class="col-2 text-right">
              {{ quota.price }}
            </div>
          </div>
        </div>
        <div class="row emobg-font-weight-bold emobg-font-medium">
          <div class="col-10">
            {{ $t('modal.insurance.total') }}:
          </div>
          <div class="col-2 text-right">
            {{ quota.price }}
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="d-flex align-items-center justify-content-center
          emobg-background-color-white emobg-border-radius-medium"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isPaymentLoading"
        class="emobg-color-white-contrast mr-2"
        data-test-id="cancel-button"
        @clickbutton="closePSD2Modal"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="!isTariffPaymentValid"
        :loading="isPaymentLoading"
        data-test-id="confirm-button"
        @clickbutton="submitPayment"
      >
        {{ $t('buttons.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
