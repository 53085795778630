<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { nameSpace as TARIFF_MODULE_NAMESPACE } from '@/stores/Tariffs/TariffsModule';
import { nameSpace as ACCOUNT_MODULE_NAMESPACE } from '@/vue/stores/UserData/UserDataStore';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'CancelTariffSubscriptionModal',
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isBusinessProfile: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { notifySuccess, notifyError } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      fetchButtonSpecs,
      notifyError,
      notifySuccess,
    };
  },
  computed: {
    ...mapState(ACCOUNT_MODULE_NAMESPACE, {
      currentUserUuid: state => get(state, 'userData.uuid'),
      currentCompanyUuid: state => get(state, 'userData.company.uuid'),
    }),
    ...mapState(TARIFF_MODULE_NAMESPACE, {
      cancelTariffStatus: state => get(state, 'cancelTariff.STATUS'),
      cancelTariffError: state => get(state, 'cancelTariff.error.message'),
    }),
    dataForRequest() {
      return {
        tariffUuid: get(this, 'tariff.uuid'),
        userUuid: this.currentUserUuid,
        companyUuid: this.currentCompanyUuid,
      };
    },
    isMigratedTariff() {
      return get(this, 'tariff.isMigrated', false);
    },
    cancelBusinessTariffRequest() {
      return this.isMigratedTariff ? this.patchCancelMigratedCompanyTariffSubscription : this.postCancelCompanyTariffSubscription;
    },
    cancelUserTariffRequest() {
      return this.isMigratedTariff ? this.patchCancelMigratedTariffSubscription : this.postCancelTariffSubscription;
    },
    cancelTariffRequest() {
      return this.isBusinessProfile ? this.cancelBusinessTariffRequest : this.cancelUserTariffRequest;
    },
  },
  methods: {
    ...mapActions(TARIFF_MODULE_NAMESPACE, [
      'patchCancelMigratedTariffSubscription',
      'patchCancelMigratedCompanyTariffSubscription',
      'postCancelTariffSubscription',
      'postCancelCompanyTariffSubscription',
    ]),
    async onCancelTariffSubscription() {
      await this.cancelTariffRequest(this.dataForRequest);

      if (!this.cancelTariffStatus.ERROR) {
        this.notifySuccess({
          text: this.$t('modal.tariff.cancel.success'),
          textAction: this.$t('buttons.ok'),
        });
        this.onSuccess();
      } else {
        this.notifyError({
          text: this.cancelTariffError || this.t('notifications.whooops'),
          textAction: this.$t('buttons.ok'),
        });
      }
      this.$emit('close-modal');
    },
  },
};
</script>
<template>
  <ui-modal
    :header="$t('modal.tariff.cancel.header', { tariff_name: tariff.name })"
    :open="isVisible"
    @close="() => $emit('close-modal')"
  >
    <div slot="body">
      {{ $t('modal.tariff.cancel.body_text') }}
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="cancelTariffStatus.LOADING"
        :size="SIZES.large"
        class="mr-3"
        data-test-id="close_modal-button"
        @clickbutton="$emit('close-modal')"
      >
        {{ $t('modal.tariff.cancel.cancel') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.DESTRUCTIVE })"
        :disabled="cancelTariffStatus.LOADING"
        :loading="cancelTariffStatus.LOADING"
        :size="SIZES.large"
        data-test-id="confirm_cancellation-button"
        @clickbutton="onCancelTariffSubscription"
      >
        {{ $t('modal.tariff.cancel.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
