<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { mapActions, mapMutations, mapState } from 'vuex';
import { nameSpace as ACCOUNT_MODULE_NAMESPACE } from '@/vue/stores/UserData/UserDataStore';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { nameSpace as TARIFFS_NAMESPACE, scopes as TARIFFS_SCOPES } from '@/stores/Tariffs/TariffsModule';
import TariffCardComponent from './components/TariffCardComponent';

export default {
  name: 'TariffsSelectorComponent',
  components: {
    TariffCardComponent,
  },
  props: {
    isBusinessProfile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    return {
      notifyError,
    };
  },
  computed: {
    ...mapState(ACCOUNT_MODULE_NAMESPACE, {
      currentUserUuid: state => get(state, 'userData.uuid'),
      currentCompanyUuid: state => get(state, 'userData.company.uuid'),
    }),
    ...mapState(TARIFFS_NAMESPACE, {
      tariffs: state => get(state, 'availableTariffs.data.data') || [],
      availableTariffsError: state => get(state, 'availableTariffs.error'),
      areTariffsLoading: state => get(state, 'availableTariffs.STATUS.LOADING') || false,
      availableTariffsStatus: state => get(state, 'availableTariffs.STATUS'),
    }),
    hasPaidTariff() {
      return !!size(filter(this.tariffs, ({ active, subscriptions }) => active && size(subscriptions)));
    },
    uuidForRequest() {
      return this.isBusinessProfile ? this.currentCompanyUuid : this.currentUserUuid;
    },
    availableTariffsRequest() {
      return this.isBusinessProfile ? this.getAvailableCompanyTariffs : this.getAvailableUserTariff;
    },
  },
  async created() {
    await this.getTariffList();
  },
  methods: {
    ...mapActions(TARIFFS_NAMESPACE, [
      'getAvailableUserTariff',
      'getAvailableCompanyTariffs',
    ]),
    ...mapActions(ACCOUNT_MODULE_NAMESPACE, [
      'fetchUserData',
    ]),
    ...mapMutations(TARIFFS_NAMESPACE, [
      'resetState',
    ]),
    async onSuccess() {
      await Promise.all([
        this.fetchUserData(),
        this.getTariffList(),
      ]);
    },
    async getTariffList() {
      this.resetState({ scopes: [TARIFFS_SCOPES.availableTariffs] });
      await this.availableTariffsRequest(this.uuidForRequest);

      if (this.availableTariffsStatus.ERROR) {
        const keyError = get(this, 'availableTariffsError.key');
        const defaultError = get(this, 'availableTariffsError.message', this.$t('notifications.whooops'));

        this.notifyError({
          text: this.$t(`api.${keyError}`, defaultError),
          textAction: this.$t('buttons.ok'),
        });
      }
    },
  },
};
</script>
<template>
  <div class="TariffsSelector">
    <ui-loader v-if="areTariffsLoading" />
    <div
      v-else
      class="TariffsSelector__wrapper d-flex flex-wrap align-items-stretch justify-content-center"
    >
      <TariffCardComponent
        v-for="tariff in tariffs"
        :key="tariff.uuid"
        :tariff="tariff"
        :has-paid-tariff="hasPaidTariff"
        :is-business-profile="isBusinessProfile"
        class="mx-4 mb-4"
        @updated:tariff="onSuccess"
      />
    </div>
  </div>
</template>
